import { Image as DatoImage } from "react-datocms";

const Divider = ({ imgUrl }) => {
  return (
    <div className="divider mt-7 h-[70px] w-full">
      <DatoImage
        data={{
          src: imgUrl?.src,
          alt: "divider",
          height: 70,
        }}
        pictureClassName="object-cover"
      />
    </div>
  );
};

export default Divider;
